/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import washingtonHero from '../../images/locationsHeroes/hero-washington.jpg'
import womanLaptopEnroll from '../../images/people/woman-laptop-enroll.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import CobraBanner from '../../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/Footer'
import header from '../../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            ' Apply for Aetna Medicare Plans in Washington | Call 833-998-1009 ',
          description:
            'Learn more about Aetna Medicare plans in Washington. Let us help you find the right plan and help answer any questions you might have. Call 833-998-1009',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/washington',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      ' Apply for Aetna Medicare Plans in Washington | Call 833-998-1009 ',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={washingtonHero}
                  alt="elderly couple go jogging on park trail"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="light">
                    Aetna<sup>®</sup> Medicare in Washington{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Aetna Medicare Insurance for Washingtonians
              </Typography>
              <Typography variant="body">
                Washington is the birthplace of competitive pickleball. And
                Aetna is the home to competitive Medicare coverage. There’s no
                need to bounce back and forth between companies when Aetna
                Medicare has you covered. Over 1.3 million Washingtonians have
                Medicare coverage,* which comes out to roughly 17% of the
                population. Find the plan that’s right for you and join other
                Washington residents in receiving Medicare benefits today!
              </Typography>
              <StaticImage
                src="../../images/illustrations/washington-stats.svg"
                alt="More than 1.3 million people in Washington have Medicare coverage*"
                layout="fixed"
                className="product-icons"
              />
              <Typography variant="h5" color="primary">
                More than 1.3 million people in Washington have Medicare
                coverage*
              </Typography>
              <Typography variant="legal">
                *Kaiser Family Foundation{' '}
                <a
                  href="https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D"
                  target="_blank"
                >
                  https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/
                </a>
              </Typography>
              <Typography variant="body">
                To learn more about the available Medicare benefits in your
                area, call to talk to a licensed agent about Aetna Medicare.
              </Typography>
            </>
          }
        ></VariableContent>

        <CobraBanner />

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Types of Medicare plans in the Evergreen State
              </Typography>
              <Typography variant="body">
                The choice comes naturally when picking between the Cougars and
                the Huskies. Choosing a Medicare plan can be easy too.
              </Typography>
            </>
          }
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-health.svg"
                    alt="health icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Advantage (Part C)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/advantage"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-dollar.svg"
                    alt="dollar icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare HMO
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/hmo"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-hospital.svg"
                    alt="hospital icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare PPO
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/ppo"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-heart.svg"
                alt="heart icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Am I eligible for Medicare in Washington?
              </Typography>
              <Typography variant="body">
                Washington residents who are 65 or older and a legal US resident
                may be eligible for Medicare. Some resident Washingtonians under
                65 may be eligible for Medicare if they have certain health
                conditions. If you’re seeking more benefits than what Original
                Medicare (Parts A and B) has to offer, enroll in a Medicare
                Advantage plan.
              </Typography>
              <Typography variant="body">
                Medicare’s varied options have different levels of eligibility.
                If you have any questions or concerns regarding your eligibility
                for Medicare plans, including Aetna Medicare Advantage, make the
                call. A friendly, licensed agent can help.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-calendar.svg"
                alt="calendar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">When can I enroll?</Typography>
              <Typography variant="body">
                There are three primary Medicare enrollment periods:
              </Typography>
              <Typography variant="h5">Initial Enrollment Period</Typography>
              <Typography variant="body">
                If you’re turning 65 soon and enrolling in Medicare for the
                first time, you have the month of your 65th birthday and a
                three-month grace period before and after your birthday known as
                the Initial Enrollment Period.
              </Typography>
              <Typography variant="h5">Annual Enrollment Period</Typography>
              <Typography variant="body">
                Already have Original Medicare and want to upgrade your coverage
                by switching to a Medicare Advantage plan? Make the switch
                during the Annual Enrollment Period which starts October 15th
                and ends December 7th every year.
              </Typography>
              <Typography variant="h5">Special Enrollment Period</Typography>
              <Typography variant="body">
                Under some circumstances, you may be able to enroll in a
                Medicare plan during a Special Enrollment Period. For example,
                you may be able to enroll in coverage or switch plans if you’ve
                recently moved to an area where your current plan is not
                covered. Talk to one of our licensed agents today to see if your
                special circumstance applies.
              </Typography>
              <Typography variant="h4" color="primary">
                Get enrolled in three simple steps:
              </Typography>
            </Column>
          </Columns>
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Call a licensed agent
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Answer some questions about your coverage needs
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-card.svg"
                    alt="card icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Sign up for a plan
                  </Typography>
                </div>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Other Medicare Resources in Washington
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title:
                        'Statewide Health Insurance Benefits Advisors (SHIBA)',
                      content: (
                        <>
                          <Typography>
                            Get all of your Medicare questions answered through
                            the{' '}
                            <a
                              href="https://www.insurance.wa.gov/statewide-health-insurance-benefits-advisors-shiba"
                              target="_blank"
                            >
                              Statewide Health Insurance Benefits Advisors
                              (SHIBA){' '}
                            </a>
                            program with free, one-on-one counseling and
                            assistance for Medicare beneficiaries and their
                            families.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Savings Program',
                      content: (
                        <>
                          <Typography>
                            If you need help paying for all or part of your
                            Medicare premiums, turn to the{' '}
                            <a
                              href="https://www.insurance.wa.gov/need-help-paying-medicare"
                              target="_blank"
                            >
                              Medicare Savings Program{' '}
                            </a>
                            for assistance.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Extra Help',
                      content: (
                        <>
                          <Typography>
                            <a
                              href="https://www.insurance.wa.gov/get-help-paying-prescription-drugs"
                              target="_blank"
                            >
                              Extra Help{' '}
                            </a>
                            from Medicare helps pay for prescription drugs that
                            you need daily, weekly, or monthly. Find out if you
                            qualify for Extra Help.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={womanLaptopEnroll}
              alt="an elderly woman enrolls in Medicare on her laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Plan in Washington today!
              </Typography>
              <Typography variant="body">
                Ready to enroll in a Medicare plan? Tap or click below to get
                started. If you need help during the enrollment process, contact
                a friendly licensed agent at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>. (TTY:
                711, available 24/7)
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
